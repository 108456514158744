
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.blog-post-content {
    h1,
    h2,
    h3 {
        padding-top: 0.375em;
        padding-bottom: 0.375em;
    }

    ul,
    ol {
        margin-bottom: 0.75em !important;
    }

    p {
        margin-bottom: 0.75em;
    }
}
.video-placeholder {
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.05);
}
